import React from 'react';
import styles from './logo.scss';

export default function Logo(props) {
    const logoStyles = () => {
        const styleList = [styles.logo, props.className];

        if (props.variant === 'dark') {
            styleList.push(styles.logoDark);
        }

        return styleList.reduce((accumulator, style) => accumulator += ` ${style}`, '');
    };

    return (
        <svg version="1.1" viewBox="0 0 601 684.95" xmlns="http://www.w3.org/2000/svg" className={logoStyles()}>
	        <path d="m81.96 3.22c-1.84 15.77-3.99 31.3-5.4 46.89-2.57 28.45-3.37 56.92 2.72 85.11 2.95 13.66 7.56 26.65 17.33 37.18 7.45 8.02 16.35 11.77 27.58 11.29 53.82-2.28 107.63-4.76 161.48-6.01 28.32-0.66 56.7 0.89 85.04 1.78 31.36 0.98 62.71 2.34 94.06 3.53 3.66 0.14 7.35 0.02 10.97 0.5 16 2.12 27.2-5.24 35.61-17.94 8.73-13.18 12.19-28.19 14.27-43.55 3.64-26.87 2.41-53.75-0.47-80.58-1.36-12.68-3.07-25.32-4.72-38.78 2.41 3.42 4.46 6.24 6.42 9.12 20.79 30.63 40.17 62.06 54.95 96.12 8.61 19.84 15.54 40.2 18.04 61.81 2.65 22.93-0.13 44.68-15.01 63.43-10.5 13.23-24.7 21.11-40.28 26.77-21.1 7.67-43.12 10.3-65.34 11.72-9.65 0.62-19.32 0.99-28.98 1.58-7.72 0.47-9.35 2.44-6.3 9.64 4.77 11.29 10.17 22.31 15.54 33.33 8.92 18.32 17.87 36.57 22.66 56.58 4.49 18.76 0.25 35.01-14.03 47.76-10.59 9.45-22.3 17.66-33.56 26.35-4.45 3.43-8.94 6.83-13.58 9.98-13.7 9.31-20.15 22.53-22.49 38.65-3.55 24.47 0.15 48.17 6.03 71.76 4.19 16.84 8.74 33.62 12.35 50.59 1.44 6.79 1.56 14.09 0.94 21.03-1.29 14.47-10.13 23.51-23.03 29.16-14.03 6.14-28.85 8.94-43.91 10.81-14.78 1.84-29.54 4.05-44.38 5.06-8.78 0.6-17.73-0.55-26.55-1.42-11.96-1.18-24.02-2.1-35.75-4.52-12.59-2.6-25.28-5.74-37.15-10.56-18.24-7.41-25.9-22.7-22.08-43.56 3.28-17.94 8.01-35.63 12.41-53.35 4.87-19.59 8.89-39.25 8.23-59.58-0.36-11.17-2.14-22.11-5.73-32.69-3.08-9.1-10.12-15.16-17.42-20.7-12.58-9.57-25.55-18.63-38.13-28.2-4.65-3.54-8.87-7.72-12.92-11.97-12.93-13.55-14.77-29.59-10.27-47.06 4.42-17.19 11.84-33.16 19.59-49.01 5.21-10.68 10.52-21.31 15.61-32.04 1.44-3.04 2.51-6.31 3.37-9.58 1.01-3.81-0.05-5.55-3.95-6.06-5.04-0.66-10.15-0.95-15.24-1.15-21.44-0.86-42.82-2.2-63.8-7.1-18.37-4.29-35.79-10.78-50.33-23.32-14.83-12.79-22.22-29.35-24.01-48.61-2.27-24.43 3.83-47.46 12.19-70.02 12.16-32.88 29.54-63.09 48.46-92.46 5.52-8.56 11.34-16.93 17.04-25.37 0.55-0.81 1.24-1.52 1.92-2.34z"/>
        </svg>
    );
};
